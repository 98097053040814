<template>
  <div>
    <v-card-actions class="pb-4 px-0">
      <BaseSearch
        dark
        v-if="loaded"
        :searchFor="search"
        :items="loadedList"
        :label="loadedList.length + ' Workouts'"
        @search_results="setResults"
        :searchIn="['name', 'type', 'alternate_name']"
      />
    </v-card-actions>
    <v-row dense>
      <v-col
        v-for="item in workouts"
        :key="item.id"
        cols="12"
        md="6"
        class="pa-0 mb-2"
      >
        <v-card rounded flat class="transparent mb-2 mx-1">
          <v-sheet class="charcoal charcoalTileMenu paper--text">
            <v-card-actions
              @click="showExerciseInfoDialog(item.workoutid)"
              :class="textSize + ' link pa-0'"
            >
              <span class="pl-1">
                {{
                  item.name.length > 40
                    ? item.name.substr(0, 40) + '...'
                    : item.name
                }}
              </span>
              <v-spacer />
            </v-card-actions>

            <v-card-actions class="pt-0 px-1 transparent silver--text">
              {{ item.type }}
              {{ item.xmode != null ? ', ' + capitalize(item.xmode) : '' }}
              <v-spacer />
              <v-icon
                :title="'Type: ' + item.type"
                :color="workoutTypeColor(item)"
              >
                mdi-checkbox-blank
              </v-icon>
            </v-card-actions>
            <v-list dense subheader class="transparent pa-0">
              <v-list-item
                v-for="(exercise, index) in item.exercises"
                :key="index"
                class="pa-0 ml-2"
              >
                <v-badge
                  color="progressActive charcoal--text"
                  left
                  inline
                  :content="exercise.sort"
                >
                  <div
                    :class="
                      textSizeXSmall + ' ml-1 silver--text text--darken-1 link'
                    "
                    :title="'View Details for ' + exercise.name"
                    @click="showExercise(exercise.id)"
                  >
                    {{ exercise.name }}
                    <span class="caption silver--text">
                      x
                      {{ exerciseInfo(exercise) }}
                    </span>
                  </div>
                </v-badge>
              </v-list-item>
            </v-list>
          </v-sheet>
          <v-sheet class="pl-1" color="charcoal charcoalTileMenu">
            <v-card-actions class="py-0 pr-0">
              <template v-if="isSelected(item.workoutid)">
                <v-icon class="mr-n2 ml-n2" color="progressActive">
                  mdi-check-circle-outline
                </v-icon>
                <span class="caption paper--text pl-3 py-2">
                  Added
                </span>
              </template>
              <template v-else>
                <v-spacer />
                <v-btn class="paper--text ml-n3" text @click="addProgram(item)">
                  <v-icon>
                    mdi-plus-circle-outline
                  </v-icon>
                  To Program
                </v-btn>
              </template>
            </v-card-actions>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
    <BaseLoading :loaded="loaded" />
  </div>
</template>
<script>
import { appConfig, builder } from '@/store/helpers.js'
import util from '@/mixins/util.js'
import axios from 'axios'
import debounce from 'lodash/debounce'

export default {
  components: {},
  name: 'WorkoutsList',
  mixins: [util],
  props: {
    type: {
      type: String
    },
    workoutsList: {
      type: Array
    }
  },
  data() {
    return {
      workouts: [],
      loadedList: [],
      search: '',
      debounce: null,
      pagesize: 500,
      currentPage: 0,
      bottom: false,
      loaded: false
    }
  },
  beforeMount() {
    this.loadPage()
  },
  mounted() {
    this.handleDebouncedScroll = debounce(this.handleScroll, 100)
    window.addEventListener('scroll', this.handleDebouncedScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleDebouncedScroll)
  },
  watch: {
    bottom(bottom) {
      if (bottom) {
        null
        /*
        this.currentPage++
        this.loadPage()
        */
      }
    }
  },
  computed: {
    ...appConfig,
    ...builder
  },
  methods: {
    setResults(value) {
      this.workouts = value
    },
    handleScroll(/*event*/) {
      this.isUserScrolling = window.scrollY > 0
      this.bottom = this.bottomVisible()
    },
    addProgram(workout) {
      workout.programType = this.type
      if (!workout.sort > 0) {
        workout.sort = this.workoutsListSize + 1
      }
      workout.num_exercises = workout.exercises.length
      this.$emit('add_workout', workout)
    },
    isSelected(id) {
      return this.workoutsList.some(sel => sel.workoutid === id)
    },
    loadPage() {
      this.loaded = false
      return axios
        .get(
          this.baseURL +
            '/workouts/scroll/all/' +
            +this.currentPage +
            '/' +
            this.pagesize,
          {}
        )
        .then(response => {
          if (response.status == 200) {
            if (response.data.data) {
              this.workouts = response.data.data
              this.workouts.forEach(el => {
                el.exercises = JSON.parse(el.exercises)
              })
              this.loadedList = this.workouts.slice()
            }

            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
